import React, { useEffect, useState } from 'react';
import componentMapping from '../config/ComponentMapping';
import './Dashboard.css';
import DashCardGrid from './DashCardGrid';
import { SERVER_URL } from '../config/config';

export default function Dashboard({sessionObject}) {


    const [components, setComponents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadComponents = async () => {
            try {
                const dashboardInfo = JSON.parse(sessionStorage.getItem('dashboard_info'))?.dashboard_info ?? [];
                console.log('Dashboard Info:', dashboardInfo);

                const componentPromises = dashboardInfo.map(async (item) => {
                    const componentLoader = componentMapping[item.menu_accessor];
                    if (!componentLoader) {
                        console.error(`No loader for ${item.menu_accessor}`);
                        return null;
                    }
                    try {
                        console.log(`Loading component for ${item.menu_accessor}`);
                        const { default: Component } = await componentLoader();
                        return { menu_accessor: item.menu_accessor, Component };
                    } catch (error) {
                        console.error(`Failed to load component for ${item.menu_accessor}:`, error);
                        return null;
                    }
                });

                const loadedComponents = await Promise.all(componentPromises);
                console.log('Loaded Components:', loadedComponents);
                setComponents(loadedComponents.filter(c => c)); // Filter out nulls
            } catch (error) {
                console.error('Error loading components:', error);
                setError('Failed to load components.');
            } finally {
                setLoading(false);
            }
        };

        loadComponents();
    }, []);

    const [uniqueStations, setUniqueStations] = useState([]);
    const [stationWeights, setStationWeights] = useState({});
  
    useEffect(() => {
      const payload = {
        date_dict: {
          date_from: new Date().toISOString(),
          date_to: new Date().toISOString()
        },
        filter_fields: {
          created_from: String(sessionObject.sessionVariables.branch_id),
          owned_by: String(sessionObject.sessionVariables.branch_id),
          companyId: "1",
          fyear: sessionObject.sessionVariables.financial_year
        },
      };
  
      fetch(`${SERVER_URL}/report/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
          extractUniqueStations(data.data);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }, []);
  
    const extractUniqueStations = (data) => {
        const branchName = sessionObject.sessionVariables.branch_name;
        const stationSet = new Set();
        const weights = {};
      
        data.forEach(item => {
          if (item.station_from_name === branchName) {
            const station = item.station_to_name;
            stationSet.add(station);
      
            if (!weights[station]) {
              weights[station] = 0;
            }
            weights[station] += item.charge_weight || 0;
          }
        });
      
        const sortedStations = Array.from(stationSet).sort();
      
        const sortedWeights = {};
        sortedStations.forEach(station => {
            sortedWeights[station] = `${weights[station]}kg`;
        });
      
        setUniqueStations(sortedStations);
        setStationWeights(sortedWeights);
    };

      

    return (
        <div className="dash">
            <div className="left-sidebar1">
                <DashCardGrid />
            </div>
            <div className="dash-content">
                <div className="dash-notification-holder">
                    <div className="dash-notification-bar">
                    {uniqueStations.length > 0 && uniqueStations.map(station => (
                        <span key={station}>
                        {station}: {stationWeights[station]}
                        </span>
                    ))}
                    </div>
                </div>
                <div className='dash-top-title'>
                    <div className='ttle'>
                        <div className='ttle-text'>
                            Dashboard
                        </div>
                    </div>
                </div>
                <div className='dash-table'>
                    {loading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div>{error}</div>
                    ) : components.length === 0 ? (
                        <div>No components to display.</div>
                    ) : (
                        components.map(({ menu_accessor, Component }) => (
                            <div key={menu_accessor} className='dash-table-content'>
                                <Component sessionObject = { sessionObject } />
                            </div>
                        ))
                    )}
                </div>

            </div>

        </div>
    );
}
